<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Boards: "Tabuleiros",
      Board: "Tabuleiro",
      "BACK": "VOLTAR",
      "To activate your board make your donation to the master.": "Para ativar o seu tabuleiro faça a sua doação para o master.",
      "Visualization of the unavailable board at the moment.": "Visualização do tabuleiro indisponível no momento.",
      "Confirm Enter": "Confirmar Entrada",
      "Do you want to confirm the entrance to the board?": "Deseja confirmar a entrada no tabuleiro?",
      "Confirm": "Confirmar",
      "GET IN TOUCH": "ENTRAR EM CONTATO",
      "Sponsor": "Patrocinador",
      "Informations": "Informações",
      "Would you like to confirm the donation in the amount of": "Deseja confirmar a doação no valor de",
      "from": "de",
      "DONATOR": "DOADOR",
      "INDICATOR": "INDICADOR",
      "COORDINATOR": "COORDENADOR",
      "MASTER": "MESTRE",
      "Attention: After receiving the first 4 donations, the master must proceed to the next board!": "Atenção: Após o recebimento das 4 primeiras doações, o master deverá seguir para o próximo tabuleiro!",
    },
    es: {
      Boards: "Tableros",
      Board: "Tablero",
      "BACK": "VOLVER",
      "To activate your board make your donation to the master.": "Para activar su tablero, haga su donación al master.",
      "Visualization of the unavailable board at the moment.": "Visualización del tablero no disponible en este momento.",
      "Confirm Enter": "Confirmar Entrada",
      "Do you want to confirm the entrance to the board?": "Quiere confirmar la entrada al tablero?",
      "Confirm": "Confirmar",
      "GET IN TOUCH": "ENTRAR EN CONTACTO",
      "Sponsor": "Patrocinador",
      "Informations": "Información",
      "Would you like to confirm the donation in the amount of": "Deseja confirmar a doação no valor de",
      "from": "de",
      "DONATOR": "DONANTE",
      "INDICATOR": "INDICADOR",
      "COORDINATOR": "COORDINADOR",
      "MASTER": "MAESTRO",
      "Attention: After receiving the first 4 donations, the master must proceed to the next board!": "Atención: Después de recibir las primeras 4 donaciones, el maestro debe proceder al próximo tablero!",
    },
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: null,

      personal: {
        status: '',
        check: '',
      },

      confirm: {
        modal: false,
        loading: false,
        type: null,
        time: 0,
      },

      board: null,
      levels: null,
      tree: null,

      modal: {
        status: false,
        loading: {
          remove: false,
          confirm: false,
        },
        user: null,
        sponsor: null,
      }
    };
  },
  methods: {
    getBoard() {
      this.statData = null,
        this.board = null;
      this.levels = null;
      this.tree = null;

      api.get("station100/boards/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.personal = response.data.personal;
          this.board = response.data.board;

          if (response.data.personal.check == 'yes') {
            this.statData = [
              {
                title: "Board",
                value: this.board.type.name,
              },
              {
                title: "Donations Received",
                value: this.$options.filters.currency(this.board.received),
              },
              {
                title: "Status",
                value: this.board.status,
              },
              {
                title: "Cycle",
                value: this.board.cycles.toString(),
              },
            ];
          } else {
            this.statData = [
              {
                title: "Board",
                value: this.board.type.name,
              },
            ];
          }

          this.levels = response.data.levels;
          this.tree = response.data.tree;
        } else {
          this.$router.push("/station100");
        }
      });
    },
    confirmPosition() {
      this.confirm.modal = true;

      api
        .get("station100/boards/time")
        .then((response) => {
          if (response.data.status == "success") {
            this.confirm.type = this.board.type.id;
            this.confirm.time = response.data.time;
          }
        });
    },
    createPosition() {
      this.confirm.loading = true;

      api
        .post("station100/boards", {
          type: this.board.type.id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.confirm.modal = false;
            this.$router.replace("/station100/" + response.data.position.id);
            this.getBoard();
          } else {
            this.confirm.modal = false;
            this.confirm.loading = false;
            this.$noty.error(response.data.message)
          }
        });
    },
    showUser(id) {
      this.modal.user = null;
      this.modal.sponsor = null;
      this.modal.status = true;

      api.get("station100/boards/user/" + id).then((response) => {
        if (response.data.status == "success") {
          this.modal.user = response.data.user
          this.modal.sponsor = response.data.sponsor
        }
      });
    },
    removeDonator(id) {
      this.modal.loading.remove = true;

      api
        .post("station100/boards/donations/remove", {
          position: id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$noty.success(response.data.message)

            this.getBoard();
          } else {
            this.$noty.error(response.data.message)
          }

          this.modal.status = false;
          this.modal.user = null;
          this.modal.loading.remove = false;
        });
    },
    confirmDonation(id) {
      this.modal.loading.confirm = true;

      api
        .post("station100/boards/donations/confirm", {
          position: id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$noty.success(response.data.message)

            this.getBoard();
          } else {
            this.$noty.error(response.data.message)
          }

          this.modal.status = false;
          this.modal.user = null;
          this.modal.loading.confirm = false;
        });
    }
  },
  mounted() {
    this.getBoard();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between align-items-center">
          <h4 class="mb-0 font-size-18">{{ t("Boards") }}</h4>
          <div class="ml-2">
            <router-link v-if="board" tag="a" :to="'/station100'" class="btn btn-light btn-sm">{{ t("BACK") }}</router-link>
            <button class="btn btn-light btn-sm ml-2" v-on:click="getBoard()"><i class="lnr lnr-sync "></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="personal.status == 'enter' && personal.check == 'no'" class="bg-white rounded p-3 mb-4 text-center">
      <button class="btn btn-default btn-lg" v-on:click="confirmPosition()">
        {{ t("Confirm Enter") }}
      </button>
    </div>
    <div v-if="personal.status == 'preview' && personal.check == 'no'" class="bg-white rounded p-3 mb-4 text-center">
      <button class="btn btn-default btn-lg" disabled>
        {{ t("Confirm Enter") }}
      </button>
      <div>
        Você precisa realizar a doação do próximo tabuleiro para entrar no infinity.
      </div>
    </div>

    <div v-if="board && board.status && board.status == 'pending'" class="bg-soft-warning p-3 mb-3 rounded d-flex align-items-center">
      <i class="lnr lnr-warning" style="font-size: 30px"></i>
      <div class="px-3 flex-fill">
        <h6 class="m-0 font-size-15">
          {{ t("To activate your board make your donation to the master.") }}
        </h6>
      </div>
    </div>

    <div v-if="board && board.status && board.approval && board.approval == 'no'" class="bg-soft-danger p-3 mb-3 rounded d-flex align-items-center">
      <i class="lnr lnr-warning" style="font-size: 30px"></i>
      <div class="px-3 flex-fill">
        <h6 class="m-0 font-size-15">
          {{ t("Attention: After receiving the first 4 donations, the master must proceed to the next board!") }}
        </h6>
      </div>
    </div>

    <div v-if="!board" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
    </div>
    <div v-else-if="board">
      <div class="tree-legend d-md-flex justify-content-center mb-2">
        <div class="tree-column text-uppercase" v-for="(row, index) in levels" :key="index">
          <strong>{{ row }}</strong>
        </div>
      </div>
      <div class="card" v-if="tree && !tree.id">
        <div class="card-body text-center">
          {{ t("Visualization of the unavailable board at the moment.") }}
        </div>
      </div>
      <div class="card" v-else-if="tree && tree.id">
        <div class="tree-body card-body p-1 py-3 d-md-flex justify-content-center align-items-center">
          <div class="tree-column tree-left text-center">
            <div class="tree-board">
              <template v-if="tree.childrens[0] && tree.childrens[0][3] && tree.childrens[0][3][0] && tree.childrens[0][3][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[0][3][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[0][3][0].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][3][0].status + ' ' + tree.childrens[0][3][0].network">{{ tree.childrens[0][3][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-donator.png')" />
                <span class="tree-user notranslate">{{ t("DONATOR") }}</span>
              </template>
            </div>

            <div class="tree-board">
              <template v-if="tree.childrens[0] && tree.childrens[0][3] && tree.childrens[0][3][1] && tree.childrens[0][3][1].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[0][3][1].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[0][3][1].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][3][1].status + ' ' + tree.childrens[0][3][1].network">{{ tree.childrens[0][3][1].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-donator.png')" />
                <span class="tree-user notranslate">{{ t("DONATOR") }}</span>
              </template>
            </div>

            <div class="tree-board">
              <template v-if="tree.childrens[0] && tree.childrens[0][3] && tree.childrens[0][3][2] && tree.childrens[0][3][2].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[0][3][2].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[0][3][2].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][3][2].status + ' ' + tree.childrens[0][3][2].network">{{ tree.childrens[0][3][2].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-donator.png')" />
                <span class="tree-user notranslate">{{ t("DONATOR") }}</span>
              </template>
            </div>

            <div class="tree-board">
              <template v-if="tree.childrens[0] && tree.childrens[0][3] && tree.childrens[0][3][3] && tree.childrens[0][3][3].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[0][3][3].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[0][3][3].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][3][3].status + ' ' + tree.childrens[0][3][3].network">{{ tree.childrens[0][3][3].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-donator.png')" />
                <span class="tree-user notranslate">{{ t("DONATOR") }}</span>
              </template>
            </div>
          </div>
          <div class="tree-column tree-left">
            <div class="tree-board">
              <template v-if="tree.childrens[0] && tree.childrens[0][2] && tree.childrens[0][2][0] && tree.childrens[0][2][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[0][2][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[0][2][0].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][2][0].status + ' ' + tree.childrens[0][2][0].network">{{ tree.childrens[0][2][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-indicator.png')" />
                <span class="tree-user notranslate">{{ t("INDICATOR") }}</span>
              </template>
            </div>

            <div class="tree-board">
              <template v-if="tree.childrens[0] && tree.childrens[0][2] && tree.childrens[0][2][1] && tree.childrens[0][2][1].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[0][2][1].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[0][2][1].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][2][1].status + ' ' + tree.childrens[0][2][1].network">{{ tree.childrens[0][2][1].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-indicator.png')" />
                <span class="tree-user notranslate">{{ t("INDICATOR") }}</span>
              </template>
            </div>
          </div>
          <div class="tree-column tree-left">
            <div class="tree-board">
              <template v-if="tree.childrens[0] && tree.childrens[0][1] && tree.childrens[0][1][0] && tree.childrens[0][1][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[0][1][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[0][1][0].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][1][0].status + ' ' + tree.childrens[0][1][0].network">{{ tree.childrens[0][1][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-coordinator.png')" />
                <span class="tree-user notranslate">{{ t("COORDINATOR") }}</span>
              </template>
            </div>
          </div>
          <div class="tree-column center">
            <div class="tree-board tree-receiver">
              <template v-if="tree">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.status + ' ' + tree.network">{{ tree.username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-master.png')" />
                <span class="tree-user notranslate">{{ t("MASTER") }}</span>
              </template>
            </div>
          </div>
          <div class="tree-column tree-right">
            <div class="tree-board">
              <template v-if="tree.childrens[1] && tree.childrens[1][1] && tree.childrens[1][1][0] && tree.childrens[1][1][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[1][1][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[1][1][0].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][1][0].status + ' ' + tree.childrens[1][1][0].network">{{ tree.childrens[1][1][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-coordinator.png')" />
                <span class="tree-user notranslate">{{ t("COORDINATOR") }}</span>
              </template>
            </div>
          </div>
          <div class="tree-column tree-right">
            <div class="tree-board">
              <template v-if="tree.childrens[1] && tree.childrens[1][2] && tree.childrens[1][2][1] && tree.childrens[1][2][1].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[1][2][1].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[1][2][1].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][2][1].status + ' ' + tree.childrens[1][2][1].network">{{ tree.childrens[1][2][1].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-indicator.png')" />
                <span class="tree-user notranslate">{{ t("INDICATOR") }}</span>
              </template>
            </div>

            <div class="tree-board">
              <template v-if="tree.childrens[1] && tree.childrens[1][2] && tree.childrens[1][2][0] && tree.childrens[1][2][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[1][2][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[1][2][0].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][2][0].status + ' ' + tree.childrens[1][2][0].network">{{ tree.childrens[1][2][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-indicator.png')" />
                <span class="tree-user notranslate">{{ t("INDICATOR") }}</span>
              </template>
            </div>
          </div>
          <div class="tree-column tree-right">
            <div class="tree-board">
              <template v-if="tree.childrens[1] && tree.childrens[1][3] && tree.childrens[1][3][3] && tree.childrens[1][3][3].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[1][3][3].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[1][3][3].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][3][3].status + ' ' + tree.childrens[1][3][3].network">{{ tree.childrens[1][3][3].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-donator.png')" />
                <span class="tree-user notranslate">{{ t("DONATOR") }}</span>
              </template>
            </div>

            <div class="tree-board">
              <template v-if="tree.childrens[1] && tree.childrens[1][3] && tree.childrens[1][3][2] && tree.childrens[1][3][2].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[1][3][2].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[1][3][2].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][3][2].status + ' ' + tree.childrens[1][3][2].network">{{ tree.childrens[1][3][2].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-donator.png')" />
                <span class="tree-user notranslate">{{ t("DONATOR") }}</span>
              </template>
            </div>

            <div class="tree-board">
              <template v-if="tree.childrens[1] && tree.childrens[1][3] && tree.childrens[1][3][1] && tree.childrens[1][3][1].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[1][3][1].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[1][3][1].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][3][1].status + ' ' + tree.childrens[1][3][1].network">{{ tree.childrens[1][3][1].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-donator.png')" />
                <span class="tree-user notranslate">{{ t("DONATOR") }}</span>
              </template>
            </div>

            <div class="tree-board">
              <template v-if="tree.childrens[1] && tree.childrens[1][3] && tree.childrens[1][3][0] && tree.childrens[1][3][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showUser(tree.childrens[1][3][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/station100/' + tree.childrens[1][3][0].icon + '.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][3][0].status + ' ' + tree.childrens[1][3][0].network">{{ tree.childrens[1][3][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/station100/' + board.type.id + '-donator.png')" />
                <span class="tree-user notranslate">{{ t("DONATOR") }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="confirm.modal" :title="t('Confirmar Entrada')" centered>
      <b-form @submit.prevent="createPosition()" class="text-center">
        <div>
          <h5 class="mb-3">
            {{ t("Do you want to confirm the entrance to the board?") }}<br><br>

            <span class="text-danger">Após a entrada no tabuleiro, você terá um prazo de {{ confirm.time }} horas para realizar a sua doação.</span>
          </h5>
          <b-button :disabled="confirm.loading == true" type="submit" variant="default">
            {{ t("Confirm") }}
            <b-spinner v-if="confirm.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </b-button>
        </div>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <b-modal v-model="modal.status" :title="t('Informations')" centered>
      <div v-if="modal.user" class="text-center">
        <div class="text-center">
          <div class="mb-3">
            <img v-if="modal.user.avatar" class="rounded-circle header-profile-user" :src="modal.user.avatar" alt="" style="width: 100px; height: 100px" />
            <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=27AAE0&color=fff&name=' +
              modal.user.name
              " alt="" style="width: 100px; height: 100px" />
          </div>
          <div class="font-size-16">
            <div class="mb-3 notranslate">
              {{ modal.user.username }}<br />
              {{ modal.user.name }}<br />
              {{ modal.user.email }}
              <template v-if="modal.user.cellphone">
                <br />
                {{ modal.user.cellphone }}<br />
                <a class="btn btn-outline-success btn-sm mt-2" target="_blank" :href="'https://api.whatsapp.com/send?phone=' +
                  modal.user.cellphone.replace('+', '').replace('.', '').replace(' ', '').replace('-', '')
                  ">
                  <div class="d-flex align-items-center justify-content-center">
                    <i class="bx bxl-whatsapp font-size-24 p-0 align-middle"></i>
                    <span class="font-size-12">{{ t("GET IN TOUCH") }}</span>
                  </div>
                </a>
              </template>
            </div>

            <div v-if="modal.sponsor.username" class="bg-soft-light px-3 pt-2 pb-2 mb-3">
              <div class="divisor">
                <span>
                  {{ t("Sponsor") }}
                  <i class="fa fa-eye font-size-15 align-middle" v-b-toggle.sponsor></i>
                </span>
              </div>
              <b-collapse id="sponsor">
                <div class="notranslate my-2">
                  {{ modal.sponsor.username }}<br />
                  {{ modal.sponsor.name }}
                  <template v-if="modal.sponsor.cellphone">
                    <br />
                    {{ modal.sponsor.cellphone }}<br />
                    <a class="btn btn-outline-success btn-sm mt-2" target="_blank" :href="'https://api.whatsapp.com/send?phone=' +
                      modal.sponsor.cellphone.replace('+', '').replace('.', '').replace(' ', '').replace('-', '')
                      ">
                      <div class="d-flex align-items-center justify-content-center">
                        <i class="bx bxl-whatsapp font-size-24 p-0 align-middle"></i>
                        <span class="font-size-12">{{ t("GET IN TOUCH") }}</span>
                      </div>
                    </a>
                  </template>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div v-if="modal.user.donator == 'yes' && modal.user.status == 'pending'" class="mt-4">
          <h5 class="mb-3 pt-3 pb-3">
            {{ t("Would you like to confirm the donation in the amount of") }} <strong>{{ modal.user.donation | currency }}</strong> {{ t("from") }} <strong class="notranslate">{{ modal.user.username
              }}</strong>?
          </h5>
          <div class="d-flex align-items-end justify-content-between text-left">
            <b-button class="btn-lg" :disabled="modal.loading.remove == true || modal.loading.confirm == true" v-on:click="removeDonator(modal.user.id)" variant="danger">
              {{ t("Remove") }}
              <b-spinner v-if="modal.loading.remove" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </b-button>
            <template v-if="modal.user.approval == 'yes'">
              <b-button class="btn-lg" :disabled="modal.loading.confirm == true || modal.loading.remove == true" v-on:click="confirmDonation(modal.user.id)" variant="default">
                {{ t("Confirm") }}
                <b-spinner v-if="modal.loading.confirm" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </template>
            <template v-else>
              <b-button class="btn-lg disabled" variant="dark">
                {{ t("Confirm") }}
                <b-spinner v-if="modal.loading.confirm" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
              </b-button>
            </template>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>

<style scoped>
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 0;
  margin-bottom: 2px;
}

.divisor span {
  display: inline-block;
  padding: 5px 10px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}

.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}

.tree-body:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background: url(~@/assets/images/boards/bg-fluid.jpeg) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: .4;
  z-index: 0;
}

.tree-column {
  width: calc(100% / 7);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.tree-board {
  position: relative;
  height: 100%;
}

.tree-board button {
  background: none;
  padding: 0;
  width: 100%;
}

.tree-user {
  position: absolute;
  top: 40%;
  left: 0;
  border-radius: 3px;
  margin: 0 10%;
  padding: 3px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: bold;
}

.tree-user.pending {
  background: red;
  color: #fff;
}

.tree-user.active,
.tree-user.completed {
  background: #27AAE0;
  color: #fff;
}

.tree-user.personal {
  background: #F6951D;
  color: #fff;
}

.tree-user.personal.pending {
  background: red;
  color: yellow;
}

.tree-pin {
  width: 100%;
  max-width: 120px;
}

.tree-receiver .tree-pin {
  max-width: 180px;
}

.tree-pin.disabled {
  filter: grayscale(1);
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .tree-pin {
    width: 100%;
    max-width: 90px;
  }
}

@media (min-width: 401px) and (max-width: 767px) {
  .tree-legend {
    display: none;
  }

  .tree-column {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    flex-direction: row-reverse;
  }

  .tree-board {
    width: 35%;
  }

  .tree-user {
    top: 35%;
    width: 80%;
  }

  .tree-pin {
    width: 100%;
    max-width: 100px;
  }

  .tree-receiver .tree-pin {
    max-width: 120px;
  }
}

@media (max-width: 400px) {

  .tree-legend {
    display: none;
  }

  .tree-column {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    flex-direction: row-reverse;
  }

  .tree-board {
    width: 35%;
  }

  .tree-user {
    top: 35%;
    width: 80%;
  }

  .tree-pin {
    width: 100%;
  }
}
</style>
